import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
// Animations
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";
// Icons
import contact1 from "../img/contact1.jpg";

const StyledSocial = styled(motion.section)`
  padding: 5rem 10rem;
  max-height: 100vh;
  height: 100%;
  color: #D9AB46;
  background-color: #1b1b1b;
  @media only screen and (min-width: 821px) and (max-width: 1200px) {
    padding: 5rem 4rem;
  }
  /* Kindel and iPad tablet  */
  @media only screen and (min-width: 540px) and (max-width: 820px) {
    padding: 5rem 2rem;
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    padding: 1.5rem;
    max-height: fit-content;
    height: 100%;
  }
`;
const Container = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
`;
const SocialLinks = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 50%;
  /* Kindel and iPad tablet  */
  @media only screen and (min-width: 540px) and (max-width: 820px) {
    width: 100%;
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    width: 100%;
  }
`;
const ContactInfo = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  /* Kindel and iPad tablet  */
  @media only screen and (min-width: 540px) and (max-width: 820px) {
    display: none;
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    display: none;
  }
`;
const ImgContainer = styled.div`
  margin: 0 auto;
`;
const Image = styled(motion.img)`
  width: 27rem;
  height: 40rem;
  object-fit: contain;
`;
const AddressBox = styled.div`
  display: inline-block;
  margin-left: 2rem;
  h2 {
    @media only screen and (min-width: 821px) and (max-width: 1200px) {
      font-size: 2.75rem;
      letter-spacing: 1.8px;
    }
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 0.95rem;
    font-weight: 200;
  }
  ul {
    margin: 0;
    list-style-type: none;
    font-weight: 200;
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    margin-left: 0;
    h2 {
      font-size: 2.5rem;
    }
  }
`;
const Footer = styled.footer`
  padding: 2.5rem 2rem 0.2rem 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media only screen and (min-width: 821px) and (max-width: 1200px) {
    justify-content: space-between;
    margin-left: 0;
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    padding: 2rem 0;
  }
`;

const ContactSocial = () => {
  const date = new Date();

  return (
    <StyledSocial
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <Container>
        <SocialLinks>
          <AddressBox>
            <h3>Address</h3>
            <p>Food Singularity,</p>
            <p>Stropkaai 27 Bus 202, Gent</p>
            <p>9000 Belgium</p>
          </AddressBox>
          <p>
                                  
                                       
                                        
          </p>
          <AddressBox>
            <h3>
                          
              E-mail</h3>
            <ul>
              <p>contact@foodsingularity.ai</p>
            </ul>
          </AddressBox>
        </SocialLinks>
        <ContactInfo>
          <ImgContainer>
            <Image src={contact1} />
          </ImgContainer>
        </ContactInfo>
      </Container>
      <Footer>
        <h1>
          <NavLink id="logo" to="/">
            Food Singularity
          </NavLink>
        </h1>
        <AddressBox>
          <p>{date.getFullYear()} Food Singularity®</p>
        </AddressBox>
      </Footer>
    </StyledSocial>
  );
};

export default ContactSocial;
