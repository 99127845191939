import React from "react";
import styled from "styled-components";
import { Layout, Description, ImageContainer } from "../styles";
// Animation
import { motion } from "framer-motion";
import { useScroll } from "../hooks/useScroll";
import { fade, photoAnimation } from "../animation";
//Import Icons
import home2 from "../img/home2.png";

const Services = styled(Layout)`
  flex-direction: row-reverse;
  justify-content: space-between;
  h2 {
    padding-bottom: 5rem;
  }
  p {
    width: 70%;
    padding: 2rem 0 4rem 0;
  }
  @media only screen and (min-width: 821px) and (max-width: 1200px) {
    h2 {
      font-size: 3rem;
    }
  }
  /* Kindel and iPad tablet  */
  @media only screen and (min-width: 540px) and (max-width: 820px) {
    flex-direction: column;
    div {
      &:first-of-type {
        padding-left: 0;
      }
    }
    p {
      font-size: 98%;
      text-align: left;
    }
  }
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    flex-direction: column;
    font-size: 20%;
    div {
      padding-left: 0;
    }
    p {
      font-size: 48%;
      text-align: left;
    }
    h2 {
      padding-bottom: 3rem;
      font-size: 1.9rem;
    }
    img {
      width: 10%;
    }
  }
`;
const Image = styled(ImageContainer)`
  /* Mobile devices iPhone, Pixel */
  @media only screen and (min-width: 320px) and (max-width: 539px) {
    img {
    width: 80vw;
    height: 100%;
    margin-bottom: 1rem;
  }
  }
  
`;

const ServicesSection = () => {
  const [element] = useScroll();

  return (
    <Services variants={fade} initial="hidden" ref={element}>
      <Description style={{ paddingRight: 0, paddingLeft: "3rem" }}>
        <h2>
          <span>Vision</span>
        </h2>
        <p>
        Harnessing advanced technology to transform the way we <span>grow</span>, <span>prepare</span>, and <span>enjoy food</span>. Its about creating a future where food is not just sustenance but a journey of <span>innovation</span>, making meals <span>tastier</span>, <span>healthier</span>, and more <span>convenient</span>, all while <span>reducing waste</span> and <span>environmental impact</span>.
        </p>
        <p>
        Our <spant>vision</spant> and <span>mission</span> at <span>Food Singularity</span> is to bring this <span>exciting future</span> to <span>your kitchen</span>.
        </p>
      </Description>
      <Image>
        <motion.img
          variants={photoAnimation}
          initial="hidden"
          animate="show"
          src={home2}
          alt=""
        />
      </Image>
    </Services>
  );
};

export default ServicesSection;
